<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'franquias' })"
            :disabled="criandoFranquia"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Franquia
        </h1>
      </v-col>
    </v-row>
    <form @submit="submitForm" :disabled="criandoFranquia">
      <v-row>
        <v-col class="pt-0 pb-0" cols="8">
          <e-label>Nome da Unidade</e-label>
          <v-text-field v-model="form.descricao" solo />
        </v-col>
        <v-col class="pt-0 pb-0" cols="4">
          <e-label>Código</e-label>
          <v-text-field v-model="form.codigo" solo />
        </v-col>
        <v-col class="pt-0 pb-0" cols="4">
          <e-label>CNPJ</e-label>
          <v-text-field v-model="form.cnpj" solo />
        </v-col>
        <v-col class="pt-0 pb-0" cols="12">
          <h1>Endereço</h1>
        </v-col>
        <v-col class="pt-0 pb-0" cols="2">
          <e-label>CEP</e-label>
          <v-text-field v-mask="'#####-###'" v-model="form.cep" solo />
        </v-col>
        <v-col class="pt-0 pb-0" cols="8">
          <e-label>Cidade</e-label>
          <v-text-field v-model="form.cidade" solo />
        </v-col>
        <v-col class="pt-0 pb-0" cols="2">
          <e-label>Estado</e-label>
          <v-text-field v-model="form.estado" solo />
        </v-col>
        <v-col class="pt-0 pb-0" cols="6">
          <e-label>Bairro</e-label>
          <v-text-field v-model="form.bairro" solo />
        </v-col>
        <v-col class="pt-0 pb-0" cols="6">
          <e-label>Logradouro</e-label>
          <v-text-field v-model="form.logradouro" solo />
        </v-col>
        <v-col class="pt-0 pb-0" cols="2">
          <e-label>Número</e-label>
          <v-text-field v-model="form.numero" solo />
        </v-col>
        <v-col class="pt-0 pb-0" cols="2">
          <e-label>Telefone</e-label>
          <v-text-field v-model="form.telefone" solo />
        </v-col>
        <v-col class="pt-0 pb-0" cols="2">
          <e-label>Celular 1</e-label>
          <v-text-field v-model="form.celular" solo />
        </v-col>
        <v-col class="pt-0 pb-0" cols="2">
          <e-label>Celular 2</e-label>
          <v-text-field v-model="form.celular2" solo />
        </v-col>
        <v-col class="pt-0 pb-0" cols="4">
          <e-label>Contexto</e-label>
          <v-text-field v-model="form.contexto" solo />
        </v-col>
        <v-col cols="12">
          <v-btn
            color="primary"
            :disabled="criandoFranquia"
            :loading="criandoFranquia"
            type="submit"
            block
            >Enviar Dados</v-btn
          >
        </v-col>
      </v-row>
    </form>
  </main-template>
</template>

<script>
export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    franquia(franquiaValue) {
      if (!franquiaValue) {
        this.form = {};
        return;
      }
      this.form = {
        ...franquiaValue,
      };
    },
    form: {
      async handler(formValue) {
        try {
          const cepValue = this.$services.cepService.getCepInteiro(formValue.cep);
          if (cepValue) {
            const localizacao = await this.$services.cepService.buscarDadosDoCep(cepValue);
            this.form.cidade = localizacao.cidade;
            this.form.estado = localizacao.estado;
            // this.form.bairro = localizacao.bairro;
            // this.form.logradouro = localizacao.rua;
          }else{
            this.form.bairro = '';
            this.form.logradouro = '';
          }
        } catch (error) {
          this.$handleError("Erro ao buscar informações do CEP");
        }
      },
      deep: true,
    },
  },
  async mounted() {
    const { franquia_id } = this.$route.params;
    this.form = await this.$services.franquiasService.getFranquia(franquia_id);
  },
  data() {
    return {
      criandoFranquia: false,
      form: {},
    };
  },
  methods: {
    async submitForm(event) {
      event.preventDefault();
      this.criandoFranquia = true;
      try {
        if (this.editing) {
          await this.$services.franquiasService.atualizarFranquia(this.form);
        } else {
          await this.$services.franquiasService.criarFranquia(this.form);
        }
        this.$toast.success("Franquia Atualizada com sucesso");
        this.$router.push({ name: "franquias" });
      } catch (error) {
        this.$handleError(error);
      }
      this.criandoFranquia = false;
    },
  },
};
</script>

<style></style>
