var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-center"},[(
      _vm.$constants.situacoesMatriculaCondition[
        _vm.removerEspaco(_vm.matricula.situacao ? _vm.matricula.situacao : '')
      ]
    )?[_c('v-currency-field',_vm._b({ref:"input",style:({
        'min-width': '60px !important',
        'max-width': '60px !important',
      }),attrs:{"locale":"en-US","default-value":null,"value":_vm.value || null,"hide-details":"","allow-negative":false,"dense":"","min":_vm.min,"decimal-length":1,"max":_vm.max,"disabled":true}},'v-currency-field',{ outlined: true },false))]:(_vm.tipoValor === 'valor_texto')?[(_vm.notaTexto)?_c('v-select',{attrs:{"items":_vm.optionsNotaTexto,"item-value":(value) => value.descricao,"item-text":(value) => value.descricao,"value":_vm.notaTexto.valor_texto,"label":"Selecione um tipo","outlined":""},on:{"change":function($event){return _vm.verificarVal($event)}}}):(_vm.valorSelect)?_c('v-select',{attrs:{"items":_vm.optionsNotaTexto,"item-value":(value) => value.descricao,"item-text":(value) => value.descricao,"value":_vm.valorSelect,"label":"Selecione um tipo","outlined":""},on:{"change":function($event){return _vm.verificarVal($event)}}}):_c('v-select',{attrs:{"items":_vm.optionsNotaTexto,"item-value":(value) => value.descricao,"item-text":(value) => value.descricao,"label":"Selecione um tipo","outlined":""},on:{"change":function($event){return _vm.verificarVal($event)}}})]:[_c('v-currency-field',_vm._b({ref:"input",style:({ 'min-width': '60px !important', 'max-width': '60px !important' }),attrs:{"locale":"en-US","default-value":null,"value":_vm.value || null,"hide-details":"","allow-negative":false,"dense":"","min":_vm.min,"max":_vm.max,"decimal-length":1,"disabled":_vm.disabled},on:{"input":(val) => _vm.handleInput(val)}},'v-currency-field',{ outlined: true },false)),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.value !== null && _vm.value !== undefined && !_vm.disabled),expression:"value !== null && value !== undefined && !disabled"}],staticClass:"red lighten-5 ml-2",attrs:{"icon":"","small":"","color":"error"},on:{"click":_vm.emitNull}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("fa fa-close")])],1)],_c('e-modal-info',{attrs:{"dadosModal":_vm.dadosModal,"showModal":_vm.showModal},on:{"closeModal":function($event){_vm.showModal = false}}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }