<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'aulas',
                  params: { gestaoDeAulaId: $route.params.gestaoDeAulaId },
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Aula
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="primary" class="mb-5">
          <v-card-text class="white--text">
            <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
            <h4>Disciplina: {{ gestaoDeAula.disciplina.descricao }}</h4>
            <h4>
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[NÃO INFORMADO]" }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="12">
            <ValidationProvider name="Tipo de Aula" rules="required" v-slot="{ errors }">
              <e-label>Tipo de Aula</e-label>
              <e-autocomplete
                :items="$constants.tiposDeAulas"
                :return-object="false"
                :error-messages="errors"
                v-model="aulaSelecionada.tipo_de_aula"
                @change="() => verificaTipoDaAula()"
                label="Selecione um tipo de aula"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row v-if="mostrarCampos">
          <v-col class="pt-0 pb-0" cols="4">
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="data"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <e-label>Selecione uma data</e-label>
                <ValidationProvider name="Data" rules="required" v-slot="{ errors }">
                  <v-text-field
                    :error-messages="errors"
                    v-model="aulaSelecionada.data"
                    persistent-hint
                    :disabled="aulaSelecionada.tipo_de_aula ? false : true"
                    label="Escolha uma data"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    solo
                  ></v-text-field>
                </ValidationProvider>
              </template>
              <v-date-picker
                v-model="data"
                scrollable
                @input="obterDiaDaSemana"
                :allowed-dates="allowedDates"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(data)"> OK </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>

          <v-col class="pt-0 pb-0" cols="4">
            <e-label v-if="momentoSelecionadoDescricao.length"
              >{{ momentoSelecionadoDescricao[0] }} {{ momentoSelecionadoDescricao[1] }}</e-label
            >
            <e-label v-else>Nenhum Momento Adicionado</e-label>
            <v-btn
              v-if="!editing"
              color="primary"
              block
              @click="
                () => {
                  rotinas = true;
                }
              "
              >Adicionar Momento</v-btn
            ><v-btn
              v-else
              color="primary"
              block
              @click="
                () => {
                  rotinas = true;
                  obterDiaDaSemana();
                }
              "
              >Editar Momento</v-btn
            >
          </v-col>
          <v-col class="pt-0 pb-0" cols="4">
            <e-label>Dia da semana</e-label>
            <v-text-field solo dense v-model="aulaSelecionada.dia_da_semana" :disabled="true">
            </v-text-field>
          </v-col>
          <v-col cols="12" v-if="gestaoDeAula.turma.curso_id === 10">
            <e-label>Campos de experiências</e-label>
            <v-row class="">
              <v-col class="pt-0 pb-0" cols="4" v-for="(el, index) of opcoes" :key="index">
                <v-checkbox
                  v-model="campos_de_experiencias[index]"
                  :label="el"
                  :value="el"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <ValidationProvider name="Conteúdo" rules="required" v-slot="{ errors }">
              <e-label v-if="gestaoDeAula.turma.curso_id === 10">Rotina</e-label>
              <e-label v-else>Conteúdo</e-label>
              <!-- Antes Conteúdo -->
              <v-textarea solo dense v-model="aulaSelecionada.conteudo" :error-messages="errors" />
            </ValidationProvider>
            <!-- {{ gestaoDeAula.turma.curso_id === 10 }} -->

            <!--<ValidationProvider
              v-if="gestaoDeAula.turma.curso_id !== 10"
              name="Metodologia"
              rules="required"
              v-slot="{ errors }"
            >
              <e-label>Metodologia</e-label>
              <v-textarea
                solo
                dense
                v-model="aulaSelecionada.metodologia"
                :error-messages="errors"
              />
            </ValidationProvider>
            -->
            <ValidationProvider name="Professor(a) de Apoio / Auxiliar" v-slot="{ errors }">
              <e-label>Professor(a) de Apoio / Auxiliar</e-label>
              <v-text-field
                solo
                dense
                v-model="aulaSelecionada.prof_auxiliar"
                :error-messages="errors"
              />
            </ValidationProvider>

            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
            <v-btn v-if="editing" class="ml-3" color="success" @click="dialog.duplicar = true"
              >Duplicar Aula</v-btn
            >
            <v-btn v-if="editing" class="ml-3" color="success" @click="dialog.exportar = true"
              >Exportar Aula</v-btn
            >
          </v-col>
        </v-row>

        <v-row v-if="!aulaSelecionada.tipo_de_aula || !aulaSelecionada.data">
          <v-col cols="12" v-if="aulaSelecionada.tipo_de_aula !== 'Aula Remota'">
            <v-alert type="warning" border="left"> Por favor, preencha todos os campos! </v-alert>
          </v-col>
        </v-row>
      </form>

      <v-bottom-sheet v-model="aulasEmConflitos" scrollable>
        <v-sheet class="text-center pa-md-8 mx-lg-auto" height="auto">
          <v-btn color="error" dark small @click="aulasEmConflitos = !aulasEmConflitos">
            Fechar
          </v-btn>

          <h2 class="mt-4">Aulas conflitadas</h2>

          <v-simple-table class="mt-4" style="border: 1px solid #e0e0e0">
            <template>
              <thead style="background-color: #e0e0e0">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Professor</th>
                  <th class="text-left">Situação</th>
                  <th class="text-left">Data/Horario</th>
                  <th class="text-left">Turma</th>
                  <th class="text-left">Disciplina</th>
                </tr>
              </thead>
              <tbody class="text-left">
                <tr v-for="item in aulasConflitadas" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>
                    {{ item.professor.nome }}
                    <br />
                    <v-chip color="grey" dark x-small>
                      {{ item.turma.franquia.descricao }}
                    </v-chip>
                  </td>
                  <td>{{ item.situacao ? item.situacao : "Aguardando" }}</td>
                  <td>
                    <span>
                      {{ item.data.split("-").reverse().join("/") }}
                    </span>
                    - {{ item.horario.descricao }}
                  </td>
                  <td>{{ item.turma.descricao }}</td>
                  <td>{{ item.disciplina.descricao }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-bottom-sheet>
    </ValidationObserver>
    <e-modal-duplicar-aula-com-rotina
      :submittingForm="submittingForm"
      :dialog="dialog.duplicar"
      :gestaoDeAulas="aulaSelecionada"
      :gestaoDeAula="gestaoDeAula"
      :form="{ ...aulaSelecionada }"
      @dialogChange="dialog.duplicar = $event"
    >
    </e-modal-duplicar-aula-com-rotina>
    <e-modal-exportar-aula-com-rotina
      :submittingForm="submittingForm"
      :dialog="dialog.exportar"
      :gestaoDeAulas="aulaSelecionada"
      :gestaoDeAula="gestaoDeAula"
      :form="{ ...aulaSelecionada }"
      @dialogChange="dialog.exportar = $event"
    >
    </e-modal-exportar-aula-com-rotina>

    <!-- rotina momento -->
    <v-bottom-sheet v-model="rotinas" scrollable>
      <v-sheet class="text-center pa-md-8 mx-lg-auto" height="auto">
        <v-btn color="error" dark small @click="rotinas = !rotinas"> Fechar </v-btn>

        <template v-if="aulaSelecionada.data">
          <h2 class="mt-4">
            Momentos disponíveis na {{ diaDaSemanaSelecionado }}-feira ({{ aulaSelecionada.data }})
          </h2>

          <v-simple-table class="mt-4" style="border: 1px solid #e0e0e0">
            <template>
              <thead style="background-color: #e0e0e0">
                <tr>
                  <th class="text-left">SEG</th>
                  <th class="text-left">TER</th>
                  <th class="text-left">QUA</th>
                  <th class="text-left">QUI</th>
                  <th class="text-left">SEX</th>
                </tr>
              </thead>
              <tbody class="text-left">
                <tr>
                  <td>
                    <template>
                      <v-treeview
                        open-all
                        item-disabled="locked"
                        :items="itensHorariosDisponiveisSeg"
                        @update:active="verificarSelecionado($event)"
                        shaped
                        hoverable
                        activatable
                      ></v-treeview>
                    </template>
                  </td>
                  <td>
                    <template>
                      <v-treeview
                        open-all
                        item-disabled="locked"
                        :items="itensHorariosDisponiveisTer"
                        @update:active="verificarSelecionado($event)"
                        shaped
                        hoverable
                        activatable
                      ></v-treeview>
                    </template>
                  </td>
                  <td>
                    <template>
                      <v-treeview
                        open-all
                        item-disabled="locked"
                        :items="itensHorariosDisponiveisQua"
                        @update:active="verificarSelecionado($event)"
                        shaped
                        hoverable
                        activatable
                      ></v-treeview>
                    </template>
                  </td>
                  <td>
                    <template>
                      <v-treeview
                        open-all
                        item-disabled="locked"
                        :items="itensHorariosDisponiveisQui"
                        @update:active="verificarSelecionado($event)"
                        shaped
                        hoverable
                        activatable
                      ></v-treeview>
                    </template>
                  </td>
                  <td>
                    <template>
                      <v-treeview
                        open-all
                        item-disabled="locked"
                        :items="itensHorariosDisponiveisSex"
                        @update:active="verificarSelecionado($event)"
                        shaped
                        hoverable
                        activatable
                      ></v-treeview>
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <template v-else>
          <v-alert class="mt-4" type="warning">Por favor, selecione primeiro uma data!</v-alert>
        </template>
      </v-sheet>
    </v-bottom-sheet>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    aula_id: {
      type: Number,
      require: true,
    },
  },
  computed: {
    tipo_de_aula() {
      return this.aulaSelecionada.tipo_de_aula;
    },
  },
  mounted() {
    this.loadData();
    if (this.editing) {
      this.mostrarCampos = true;
      this.outrosCampos = true;
    }
  },
  data() {
    return {
      opcoes: [
        "O eu, o outro e o nós",
        "Corpo, gestos e movimentos",
        "Escuta, fala, pensamento e imaginação",
        "Traços, sons, cores e formas",
        "Espaço, tempo, quantidades, relações e transformações",
      ],
      campos_de_experiencias: [],
      diasPermitidos: [1, 2, 3, 4], // Desabilita segunda, terça, quarta e quinta-feira (0 = Domingo, 1 = Segunda, 2 = Terça, ..., 6 = Sábado)
      momentoSelecionado: [],
      rotinas: false,
      data: null,
      modal: false,
      menu: false,
      aulasEmConflitos: false,
      aulasConflitadas: [],
      mostrarCampos: true,
      outrosCampos: false,
      submittingForm: false,
      form: {},
      aulaSelecionada: {
        data: null,
        dia_da_semana: null,
      },
      gestaoDeAulas: null,
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      aulaDuplicada: {},
      dialog: {
        duplicar: false,
        exportar: false,
      },
      horarios: [],
      horarioSelecionado: {
        descricao: "Escolha um horario",
      },
      horariosDaAula: [],

      itensHorariosDisponiveisSeg: [],
      itensHorariosDisponiveisTer: [],
      itensHorariosDisponiveisQua: [],
      itensHorariosDisponiveisQui: [],
      itensHorariosDisponiveisSex: [],
      rotina: [],
      diasPermitidosParaCriarAula: [0, 1, 2, 3, 4],
      diaDaSemanaSelecionado: null,
      momentoSelecionadoDescricao: [],
    };
  },
  watch: {
    tipo_de_aula() {
      if (this.aulaSelecionada.tipo_de_aula === "Aula Remota") {
        this.aulaSelecionada.horario_inicial = null;
        this.aulaSelecionada.horario_final = null;
        this.horarioSelecionado = null;
      }
    },
    data(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", {
        timeZone: "UTC",
      }).format(new Date(value));
      this.aulaSelecionada.data = data_old.replaceAll("/", "/");
      const diasDaSemana = [
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
        "Domingo",
      ];
      const diaSelecionado = new Date(value).getDay(); // Retorna um número de 0 a 6 (0 é Segunda-feira)
      this.aulaSelecionada.dia_da_semana = diasDaSemana[diaSelecionado];
    },
  },
  methods: {
    verificarSelecionado(item) {
      const momentosNaoPode = [1, 5, 9, 13, 17, 21, 25, 29, 33, 37, 41];
      if (momentosNaoPode.includes(item[0])) {
        this.$toast.danger("Selecione um turno do momento!!");
      } else {
        this.momentoSelecionado = item[0];

        this.$constants.rotinasTurma.map((item) => {
          return item.children.map((subItem) => {
            if (subItem.id === this.momentoSelecionado) {
              const pai = subItem.pai;

              const paiName = this.$constants.rotinasTurma.filter((item) => item.id === pai)[0]
                .name;

              this.momentoSelecionadoDescricao = [];
              this.momentoSelecionadoDescricao.push(paiName);
              this.momentoSelecionadoDescricao.push(subItem.name);
            }
          });
        });
        if (!this.editing) {
          this.$toast.success("Momento Selecionado com sucesso!");
        }
        this.rotinas = false;
      }
    },
    allowedDates(val) {
      const dias = JSON.parse(this.gestaoDeAula.momentos);
      const arrayPermitidos = [];
      const segunda = Array.isArray(dias.segunda) ? arrayPermitidos.push(0) : false;
      const terca = Array.isArray(dias.terca) ? arrayPermitidos.push(1) : false;
      const quarta = Array.isArray(dias.quarta) ? arrayPermitidos.push(2) : false;
      const quinta = Array.isArray(dias.quinta) ? arrayPermitidos.push(3) : false;
      const sexta = Array.isArray(dias.sexta) ? arrayPermitidos.push(4) : false;

      const array = arrayPermitidos;
      return array.includes(new Date(val).getDay());
    },
    obterDiaDaSemana() {
      if (this.editing) {
        const partes = this.aulaSelecionada.data.split("/");
        const dataFormatada = `${partes[2]}-${partes[1]}-${partes[0]}`;
        // this.data = dataFormatada;
        this.aulaSelecionada.data = dataFormatada;
      }
      if (this.data) {
        const diaDaSemana = new Date(this.data).getDay();
        const diasDaSemana = ["segunda", "terca", "quarta", "quinta", "sexta", "sabado", "domingo"];
        const nomeDoDiaDaSemana = diasDaSemana[diaDaSemana];
        this.diaDaSemanaSelecionado = nomeDoDiaDaSemana;
        this.loadMomentos();
      }
    },
    // async pesquisaAulas() {
    //   const data_aula = this.aulaSelecionada.data;
    //   /* eslint-disable */
    //   const aula_id = this.$route.params.aula_id;
    //   const horario_id = this.horarioSelecionado.id;
    //   const turma_id = this.gestaoDeAula.turma.id;
    //   if (!data_aula || !this.horarioSelecionado || !this.aulaSelecionada) return;

    //   const response = await this.$services.aulasService.pesquisarAulasEmConflitos(
    //     data_aula,
    //     horario_id,
    //     turma_id,
    //     aula_id
    //   );

    //   if (response.data.length <= 0) {
    //     this.mostrarCampos = true;
    //     this.outrosCampos = true;
    //   }

    //   if (response.data.length > 0) {
    //     this.aulasEmConflitos = true;
    //     this.outrosCampos = false;
    //     this.aulasConflitadas = response.data;
    //   }
    // },
    async verificaTipoDaAula() {
      if (this.aulaSelecionada.tipo_de_aula === "Aula Remota" || this.editing) {
        this.getAula();
        this.mostrarCampos = true;
        this.outrosCampos = true;
        return;
      }
      this.mostrarCampos = true;
      this.outrosCampos = false;
    },
    async submitForm() {
      if (Array.isArray(this.horariosDaAula)) {
        this.aulaSelecionada.horarios = this.horariosDaAula;
      }
      this.aulaSelecionada.campos_de_experiencias = this.campos_de_experiencias;
      // this.aulaSelecionada.dia_da_semana = this.dia_da_semana;
      if (this.momentoSelecionadoDescricao.length) {
        this.submittingForm = true;

        this.aulaSelecionada.momento = this.momentoSelecionado;

        try {
          if (this.editing) {
            this.aulaSelecionada.gestao_de_aula = parseInt(this.$route.params.gestaoDeAulaId, 10);
            this.aulaSelecionada.data = this.aulaSelecionada.data.split("/").reverse().join("-");
            await this.$services.aulasService.atualizar(this.aulaSelecionada);
            this.$toast.success("Aula editada com sucesso!");
          } else {
            this.aulaSelecionada.gestao_de_aula = parseInt(this.$route.params.gestaoDeAulaId, 10);
            this.aulaSelecionada.disciplina_id = this.gestaoDeAula.disciplina_id;
            this.aulaSelecionada.turma_id = parseInt(this.gestaoDeAula.turma_id, 10);
            this.aulaSelecionada.instrutor_id = parseInt(this.gestaoDeAula.instrutor_id, 10);
            this.aulaSelecionada.data = this.aulaSelecionada.data.split("/").reverse().join("-");
            await this.$services.aulasService.criar(this.aulaSelecionada);
            this.$router.push({
              name: "aulas",
            });
            this.$toast.success("Aula cadastrada com sucesso!");
          }
        } catch (error) {
          this.$handleError(error);
        }
        this.submittingForm = false;
      } else {
        this.$toast.danger("Selecione o momento da aula");
      }
    },
    async getAula() {
      const { aula_id } = this.$route.params;
      const response = await this.$services.gestoesDeAulasService.listarAulasGestao(
        this.$route.params.gestaoDeAulaId
      );
      this.gestaoDeAula = response.payload.gestaoDeAula;

      this.loadMomentos();

      const aulaSelecionada = await this.$services.aulasService.getAula(parseInt(aula_id, 10));

      this.horariosDaAula = aulaSelecionada.horarios ?? [];

      let editando = false;

      if (aula_id) {
        editando = true;
      }

      if (editando) {
        this.horarios = response.payload.horarios;
      } else {
        this.horarios = response.payload.horarios;
        // if (this.gestaoDeAula?.turma?.curso_id == 10) {
        //   this.horarios = response.payload.horarios.filter((h) => h.id == 16);
        //   if (this.gestaoDeAula.turma.turno_id == 2) {
        //     this.horarios = response.payload.horarios.filter((h) => h.id == 17);
        //   }
        // } else {
        //   this.horarios = response.payload.horarios;
        // }
      }

      //   this.horarios = response.payload.horarios.filter((h) => h.id != 16);

      if (this.gestaoDeAula.turma.curso_id === 10) {
        this.horarioSelecionado = aulaSelecionada.horarios_vinculado ?? null;
      } else {
        this.horarioSelecionado = aulaSelecionada.horario ?? null;
      }

      this.campos_de_experiencias = aulaSelecionada.campos_de_experiencias
        ? aulaSelecionada.campos_de_experiencias
        : [];

      this.dia_da_semana = aulaSelecionada.dia_da_semana ?? null;

      return aulaSelecionada;
    },
    async loadData() {
      try {
        this.$loaderService.open("Carregando dados...");
        this.aulaSelecionada = await this.getAula();
        if (this.aulaSelecionada.momento) {
          this.momentoSelecionado.push(this.aulaSelecionada.momento);
          this.verificarSelecionado(this.momentoSelecionado);
        }

        if (this.aulaSelecionada.data) {
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("-").reverse().join("/");
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async loadMomentos() {
      /// inicio rotinas momentos
      this.rotina = JSON.parse(this.gestaoDeAula.momentos);

      // segunda-feira
      const arrayNovoSeg = [];
      if (Array.isArray(this.rotina.segunda)) {
        this.rotina.segunda.map((idSelecionado) => {
          return this.$constants.rotinasTurma.map((item) => {
            return item.children.map((subItem) => {
              if (subItem.id === idSelecionado) {
                arrayNovoSeg.push(subItem);
              }
            });
          });
        });

        const rotinaNovaSelecionadaSegunda = [];
        arrayNovoSeg.map((item) => {
          const search = rotinaNovaSelecionadaSegunda.find((subItem) => subItem.id === item.pai);
          if (search) {
            search.children.push(item);
          } else {
            const getName = this.$constants.rotinasTurma.filter(
              (rotina) => rotina.id === item.pai
            )[0].name;
            const itemNovo = {
              id: item.pai,
              name: getName,
              locked: this.diaDaSemanaSelecionado !== "segunda",
              children: [],
            };
            rotinaNovaSelecionadaSegunda.push(itemNovo);
            const search = rotinaNovaSelecionadaSegunda.find((subItem) => subItem.id === item.pai);
            search.children.push(item);
          }
        });

        this.itensHorariosDisponiveisSeg = rotinaNovaSelecionadaSegunda;
      }

      // terca-feira
      const arrayNovoTer = [];
      if (Array.isArray(this.rotina.terca)) {
        this.rotina.terca.map((idSelecionado) => {
          return this.$constants.rotinasTurma.map((item) => {
            return item.children.map((subItem) => {
              if (subItem.id === idSelecionado) {
                arrayNovoTer.push(subItem);
              }
            });
          });
        });

        const rotinaNovaSelecionadaTerca = [];
        arrayNovoTer.map((item) => {
          const search = rotinaNovaSelecionadaTerca.find((subItem) => subItem.id === item.pai);
          if (search) {
            search.children.push(item);
          } else {
            const getName = this.$constants.rotinasTurma.filter(
              (rotina) => rotina.id === item.pai
            )[0].name;
            const itemNovo = {
              id: item.pai,
              name: getName,
              locked: this.diaDaSemanaSelecionado !== "terca",
              children: [],
            };
            rotinaNovaSelecionadaTerca.push(itemNovo);
            const search = rotinaNovaSelecionadaTerca.find((subItem) => subItem.id === item.pai);
            search.children.push(item);
          }
        });

        this.itensHorariosDisponiveisTer = rotinaNovaSelecionadaTerca;
      }

      // quarta-feira
      const arrayNovoQua = [];

      if (Array.isArray(this.rotina.quarta)) {
        this.rotina.quarta.map((idSelecionado) => {
          return this.$constants.rotinasTurma.map((item) => {
            return item.children.map((subItem) => {
              if (subItem.id === idSelecionado) {
                arrayNovoQua.push(subItem);
              }
            });
          });
        });

        const rotinaNovaSelecionadaQuarta = [];
        arrayNovoQua.map((item) => {
          const search = rotinaNovaSelecionadaQuarta.find((subItem) => subItem.id === item.pai);
          if (search) {
            search.children.push(item);
          } else {
            const getName = this.$constants.rotinasTurma.filter(
              (rotina) => rotina.id === item.pai
            )[0].name;
            const itemNovo = {
              id: item.pai,
              name: getName,
              locked: this.diaDaSemanaSelecionado !== "quarta",
              children: [],
            };
            rotinaNovaSelecionadaQuarta.push(itemNovo);
            const search = rotinaNovaSelecionadaQuarta.find((subItem) => subItem.id === item.pai);
            search.children.push(item);
          }
        });

        this.itensHorariosDisponiveisQua = rotinaNovaSelecionadaQuarta;
      }

      // quinta-feira
      const arrayNovoQui = [];

      if (Array.isArray(this.rotina.quinta)) {
        this.rotina.quinta.map((idSelecionado) => {
          return this.$constants.rotinasTurma.map((item) => {
            return item.children.map((subItem) => {
              if (subItem.id === idSelecionado) {
                arrayNovoQui.push(subItem);
              }
            });
          });
        });

        const rotinaNovaSelecionadaQuinta = [];
        arrayNovoQui.map((item) => {
          const search = rotinaNovaSelecionadaQuinta.find((subItem) => subItem.id === item.pai);
          if (search) {
            search.children.push(item);
          } else {
            const getName = this.$constants.rotinasTurma.filter(
              (rotina) => rotina.id === item.pai
            )[0].name;
            const itemNovo = {
              id: item.pai,
              name: getName,
              locked: this.diaDaSemanaSelecionado !== "quinta",
              children: [],
            };
            rotinaNovaSelecionadaQuinta.push(itemNovo);
            const search = rotinaNovaSelecionadaQuinta.find((subItem) => subItem.id === item.pai);
            search.children.push(item);
          }
        });

        this.itensHorariosDisponiveisQui = rotinaNovaSelecionadaQuinta;
      }

      // sexta-feira
      const arrayNovoSex = [];

      if (Array.isArray(this.rotina.sexta)) {
        this.rotina.sexta.map((idSelecionado) => {
          return this.$constants.rotinasTurma.map((item) => {
            return item.children.map((subItem) => {
              if (subItem.id === idSelecionado) {
                arrayNovoSex.push(subItem);
              }
            });
          });
        });

        const rotinaNovaSelecionadaSexta = [];
        arrayNovoSex.map((item) => {
          const search = rotinaNovaSelecionadaSexta.find((subItem) => subItem.id === item.pai);
          if (search) {
            search.children.push(item);
          } else {
            const getName = this.$constants.rotinasTurma.filter(
              (rotina) => rotina.id === item.pai
            )[0].name;
            const itemNovo = {
              id: item.pai,
              name: getName,
              locked: this.diaDaSemanaSelecionado !== "sexta",
              children: [],
            };
            rotinaNovaSelecionadaSexta.push(itemNovo);
            const search = rotinaNovaSelecionadaSexta.find((subItem) => subItem.id === item.pai);
            search.children.push(item);
          }
        });

        this.itensHorariosDisponiveisSex = rotinaNovaSelecionadaSexta;
      }

      /// fim rotinas momentos
    },
  },
};
</script>

<style scoped>
.dataInput {
  background-color: white;
  width: 100%;
  height: 35px;
  box-shadow: 2px 2px rgb(0, 0, 0, 0.1);
}
</style>
