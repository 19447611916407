import Axios from "@/plugins/Axios";

export class RelatoriosService {
  async verificarBoletimGeral(turma) {
    const response = await Axios().get(`relatorios/verificar-boletim-geral/${turma}`);
    return response;
  }

  async listarTurma(turma) {
    const response = await Axios()
      .get(`relatorios/listarTurma/${turma}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `listar_turma_${turma}.pdf`;
        link.click();
      });
    return response;
  }

  async ataTurma(turma) {
    const response = await Axios()
      .get(`relatorios/ataTurma/${turma}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `ata_turma_${turma}.pdf`;
        link.click();
      });
    return response;
  }

  async boletimGeral(turma) {
    const response = await Axios()
      .get(`relatorios/boletimGeral/${turma}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        // const link = document.createElement("a");
        // link.href = window.URL.createObjectURL(blob);
        // link.download = `boletim_turma_${turma}.pdf`;
        // link.click();
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatorioBNCC(matricula_id) {
    const response = await Axios()
      .get(`relatorios/relatorioBNCC/${matricula_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `ralatorio_bncc_matricula_${matricula_id}.pdf`;
        link.click();
      });
    return response;
  }

  async boletimIndividual(matricula_id) {
    const response = await Axios()
      .get(`relatorios/turmaBoletimIndividual/${matricula_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async alunosPorUnidadeEScolar(franquia_id) {
    const response = await Axios()
      .get(`relatorios/alunos_por_unidade/${franquia_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "alunos_por_unidade.pdf";
        link.click();
      });
    return response;
  }

  async boletinsFranquia(franquia_id) {
    const response = await Axios()
      .get(`relatorios/boletins-franquia/${franquia_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `boletins_franquia_${franquia_id}.pdf`;
        link.click();
      });
    return response;
  }

  async relatoriosBnccFranquia(franquia_id) {
    const response = await Axios()
      .get(`relatorios/relatorios-bncc-franquia/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `relatorios_bncc_franquia_${franquia_id}.pdf`;
        link.click();
      });
    return response;
  }

  async escolasTurmasAlunos() {
    const response = await Axios()
      .get("relatorios/escolas_turmas_alunos", { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "escola_relatorio_completo.pdf";
        link.click();
      });
    return response;
  }

  async listarTodasTurmas() {
    const response = await Axios()
      .get("relatorios/listar-todas-turmas", { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "todas_as_turmas_de_todas_escolas.pdf";
        link.click();
      });
    return response;
  }

  async listarTurmasFranquia(franquia_id) {
    const response = await Axios()
      .get(`relatorios/listar-turmas-franquia/${franquia_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "todas_as_turmas_de_uma_escola.pdf";
        link.click();
      });
    return response;
  }

  async inscritosCongresso() {
    const response = await Axios()
      .get("relatorios/inscritos-congresso", { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "inscritos_congresso.pdf";
        link.click();
      });
    return response;
  }

  async diarioFrequencia(gestao_id, mes) {
    const { id } = mes;
    const response = await Axios()
      .get(`relatorios/diario-frequencia/${gestao_id}/${id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `diario_frequencia_${gestao_id}.pdf`;
        link.click();
      });
    return response;
  }

  async diarioAulas(gestao_id, mes) {
    const { id } = mes;
    const response = await Axios()
      .get(`relatorios/diario-aulas/${gestao_id}/${id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `diario_aulas_${gestao_id}.pdf`;
        link.click();
      });
    return response;
  }

  async diarioNotas(gestao_id) {
    const response = await Axios()
      .get(`relatorios/diario-notas/${gestao_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `diario_notas_${gestao_id}.pdf`;
        link.click();
      });
    return response;
  }

  async insercaoAulas() {
    const response = await Axios()
      .get("relatorios/insercao-aulas", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "insercao_aulas.pdf";
        link.click();
      });
    return response;
  }

  async lotacao() {
    const response = await Axios()
      .get("relatorios/relatorio-lotacao", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "relatorio_lotacao.pdf";
        link.click();
      });
    return response;
  }

  async planoBimestral(gestao_id, bimestre) {
    const response = await Axios()
      .get(`relatorios/plano-bimestral/${gestao_id}/${bimestre}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `plano_bimestral_${gestao_id}.pdf`;
        link.click();
      });
    return response;
  }

  async planoMensal(gestao_id, mes, periodo) {
    const response = await Axios()
      .get(`relatorios/plano-mensal/${gestao_id}/${mes}/${periodo}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `plano_mensal_${gestao_id}.pdf`;
        link.click();
      });
    return response;
  }

  async resultadoPorDisciplina(turma) {
    const response = await Axios()
      .get(`relatorios/resultado-por-disciplinas/${turma}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `resultado_por_disciplina_${turma}.pdf`;
        link.click();
      });
    return response;
  }

  async capaDiarioClasse(gestao_id) {
    const response = await Axios()
      .get(`relatorios/capa/diario-classes/${gestao_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `capa_de_diario_${gestao_id}.pdf`;
        link.click();
      });
    return response;
  }

  async resumoMovimentoEscolar(franquia_id) {
    const response = await Axios()
      .get(`relatorios/resumo-movimento-escolar/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `resumo_movimento_escolar_${franquia_id}.pdf`;
        link.click();
      });
    return response;
  }

  async declaracaoConclusao(matricula_id) {
    const response = await Axios()
      .get(`relatorios/declaracao-conclusao/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `declaracao_de_conclusao_${matricula_id}.pdf`;
        link.click();
      });
    return response;
  }

  async declaracaoCurso(matricula_id) {
    const response = await Axios()
      .get(`relatorios/declaracao-curso/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `ficha_de_matricula${matricula_id}.pdf`;
        link.click();
      });
    return response;
  }

  async fichaDeMatricula(matricula_id) {
    const response = await Axios()
      .get(`relatorios/ficha-matricula/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `ficha_de_matricula_${matricula_id}.pdf`;
        link.click();
      });
    return response;
  }

  async qtdProfessoresPorDisciplina() {
    const response = await Axios()
      .get(`relatorios/professores-por-disciplina`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `qtd_professores_por_disciplina.pdf`;
        link.click();
      });
    return response;
  }

  async documentoJustificativaFalta(aula_id, matricula_id) {
    const response = await Axios()
      .get(`relatorios/falta/baixar-documento/${aula_id}/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `documento_de_justificativa_${matricula_id}.pdf`;
        link.click();
      });
    return response;
  }

  async alunosNaoMatriculados(franquia_id) {
    const response = await Axios()
      .get(`relatorios/alunos-nao-matriculados/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `alunos_nao_matriculados_${franquia_id}.pdf`;
        link.click();
      });
    return response;
  }

  async alunosNaoMatriculadosGeral() {
    const response = await Axios()
      .get(`relatorios/alunos-nao-matriculados-geral`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `alunos_nao_matriculados_geral.pdf`;
        link.click();
      });
    return response;
  }

  async relatoriosEvasao(franquia_id) {
    const response = await Axios()
      .get(`relatorios/evasao/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `relatorio_evasao_${franquia_id}.pdf`;
        link.click();
      });
    return response;
  }

  async relatorioAulas(franquia_id) {
    const response = await Axios()
      .get(`relatorios/aulas/franquia/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `relatorio_de_aulas_${franquia_id}.pdf`;
        link.click();
      });
    return response;
  }

  async relatorioDeQuantidadeDeAulas(franquia_id) {
    const response = await Axios()
      .get(`relatorios/quantidade-de-aulas-por-disciplina/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `relatorio_de_aulas_${franquia_id}.pdf`;
        link.click();
      });
    return response;
  }

  async controleMatriculasXls(franquia_id) {
    const response = await Axios()
      .get(`relatorios/controle-matricula/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/xls" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `controle_matriculas_${franquia_id}.xls`;
        link.click();
      });
    return response;
  }

  async planoMensalInfantil(gestao_id, mes, periodo) {
    const response = await Axios()
      .get(`relatorios/plano-mensal/infantil/${gestao_id}/${mes}/${periodo}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `plano_mensal_infantil${gestao_id}.pdf`;
        link.click();
      });
    return response;
  }

  async relatorioEspecial(matricula_id, disciplina_id, periodo_id, mes_id) {
    const response = await Axios()
      .get(`relatorios/plano-especial/${matricula_id}/${mes_id}/${periodo_id}/${disciplina_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `relatorio-especial${matricula_id}.pdf`;
        link.click();
      });
    return response;
  }

  async frenquenciaIndividual(matricula_id, mes) {
    const response = await Axios()
      .get(`relatorios/frenquencia-individual/${matricula_id}/${mes}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `frequencia_individual_${matricula_id}.pdf`;
        link.click();
      });
    return response;
  }

  async relatorioIndividual(matricula_id) {
    const response = await Axios()
      .get(`relatorios/relatorio-individual/matricula/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `relatorio-individual${matricula_id}.pdf`;
        link.click();
      });
    return response;
  }

  async planoMensalAntigo(gestao_id, mes) {
    const response = await Axios()
      .get(`relatorios/plano-mensal-antigo/${gestao_id}/${mes}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `plano_mensal_antigo${gestao_id}.pdf`;
        link.click();
      });
    return response;
  }

  async relatorioDeListagemDosPlanosLancados(franquia_id) {
    const response = await Axios()
      .get(`relatorios/historico-lancamentos-planos/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `historico_lancamento_planos_${franquia_id}.pdf`;
        link.click();
      });
    return response;
  }

  async relatorioDeListagemDosPlanosLancadosGeral() {
    const response = await Axios()
      .get(`relatorios/historico-lancamentos-planos-geral`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `historico_lancamento_planos_geral.pdf`;
        link.click();
      });
    return response;
  }

  async relatorioBNCCTurma(turma_id) {
    const response = await Axios()
      .get(`relatorios/relatorio-bncc/${turma_id}/turma`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `ralatorio_bncc_turma_${turma_id}.pdf`;
        link.click();
      });
    return response;
  }

  async carteira() {
    const base64 = await Axios()
      .get(`relatorios/relatorio-bncc/760/turma`, { responseType: "arraybuffer" })
      .then((response) => Buffer.from(response.data, "binary").toString("base64"));
    return `data:application/pdf;base64, ${base64}`;
  }

  async relatoriosCensoFranquia(franquias_id) {
    const response = await Axios()
      .get(`relatorios/censo/${franquias_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `censo_${franquias_id}.pdf`;
        link.click();
      });
    return response;
  }
}
export default new RelatoriosService();
